@font-face {
    font-family: 'Bubbleboddy Neue Trial';
    src: url("../fonts/Bubbleboddy Neue-trial_0.ttf") format("truetype");
    src: url("../fonts/bubbleboddy_neue-trial_0-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal; }
  
@font-face {
      font-family: 'OfficinaSansBookC';
      src: url("../fonts/OfficinaSansBookC.otf") format("opentype");            
      font-weight: normal;
      font-style: normal;
}

@font-face {
  font-family: 'OfficinaSansBookC';
  src: url('../fonts/officinasansbookc-webfont.eot');
  src: url('../fonts/officinasansbookc-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/officinasansbookc-webfont.woff2') format('woff2'),
       url('../fonts/officinasansbookc-webfont.woff') format('woff'),
       url('../fonts/officinasansbookc-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


  @font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Regular_0.ttf") format("truetype");
    font-weight: normal;
    font-style: normal; }
 @font-face {
        font-family: 'BebasNeue Regular';
        src: url("../fonts/BebasNeue Regular.otf") format("opentype");
        font-weight: normal;
        font-style: normal; }
 
   