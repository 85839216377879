html {
    height: 100%;
}
body {
    background: #fff;
    @include font1;
    height: 100%; 
	box-sizing: border-box;  
	@include font1; 
}
a {
	color:#000;
}
section {
    height: auto;
    min-height: 650px;
    text-align: center;
}
.section__first {
    background: url(../images/first-bg.png);
    background-size: cover;    
}
header {
    width: 100%;
    display: flex;
    align-items: center;
	padding-top: 50px;
	
}
.left-logo-container {
    width: 30%;
    background: #056839;
	text-align: right;
	@media screen and (max-width: 1240px){
		height: 39px;
		width: 180px;
    }
}
.left-logo-container img {
	@media screen and (max-width: 1240px){
        width: 160px;
    }
}

.right-logo-container {
    padding: 0 0 0 30px;
}
.right-logo-container img {
	@media screen and (max-width: 1240px){
        width: 90px;
    }
	
}
nav {
	padding-left: 80px;
	@media screen and (max-width: 1350px){
        padding-left: 30px;
	}
	@include phone {
		display: none;
	}
}
.menu {
	display: flex;
	justify-content: flex-start;
	width: 800px;
	height: 50px;
	align-items: center;
	padding: 0;
	
}

.menu li {
float:left;
position:relative;
width:auto; 
text-align:center;
display:flex;
align-items:center;
justify-content:center;
height:70px;	
}
.menu li:nth-child(1){
width:130px;
@media screen and (max-width: 1099px){
	width:90px;
}
}
.menu li:nth-child(2){
width:100px;
@media screen and (max-width: 1099px){
	width:90px;
}
}
.menu li:nth-child(3){
width:230px;
@media screen and (max-width: 1099px){
	width:180px;
}
}
.menu li:nth-child(4){
width:170px;
@media screen and (max-width: 1099px){
	width:130px;
}
}
.menu li:nth-child(5){
width:170px;
@media screen and (max-width: 1099px){
	width:130px;
}
}
.menu li a {
display:block;  
}

.menu li a span{
display:block;
padding: 10px 15px;
font-weight:700;
font-size: 18px;
@media screen and (max-width: 1099px){
	font-size: 16px;
}
}
.menu li:hover span{
color: #fff;
}
.menu li:hover a{
background-color: #0880c2;
background-image: linear-gradient(to top, #0c4688 0%, #0881c3 100%);
border:5px solid #fff;
border-radius:18px;
}

.banner {
    margin: 4% auto 0;
    max-width:1250px;
}
.banner__list {
	display: flex;
	align-items: center;	
	padding: 0 0 130px 0;
	@include tablet {		
		flex-wrap: wrap;
		justify-content: center;
	}
}
.banner__item {
    display: flex;
    flex-direction: column;
	width: 30%; 
	align-items: center; 
	@include tablet {		
		width: 80%;
	}
	
}
.banner__item:nth-child(1) {
	width: 30%;
	@include tablet {		
		width: 80%;
	}
	
}
.banner__item:nth-child(2) {
	width: 40%;
	@include tablet {		
		width: 80%;
	}
}
.banner__item:nth-child(3) {
	width: 25%;
	@include tablet {		
		width: 80%;
	}
}
.banner__img img {
    width: 320px;
}
.banner__title img {
width: 345px;
@include tablet--big {
	width: 280px;
}
}
.sub-title {
	display: inline-block;
}

.banner__title .t50 {
	font-family: "Bubbleboddy Neue Trial";
    font-size: 180px;
}
.banner__text {
	@include font2;
	font-weight: bold;
	color:#fff;
	font-size: 20px;
	@include tablet--big {
		font-size: 18px;
	}
}
.banner__slogan {
font-family: "Bubbleboddy Neue Trial";
font-size: 42px;
font-weight: 700;
line-height: 36px;
color: #ffffff;
width: 400px;
display: inline-block;

@include phone {
	font-size: 16px;
}

}

.section__toys {
	background: url('../images/toys-bg.png');
    background-size: cover;
	margin-top: -25px;
	position: relative;
	min-height: 100%;
	
}
.section__container {
	max-width: 1250px;
	margin: 0 auto;
}
.cont-rel {
	position: relative;
}
.wave {
	position: absolute;
	top:-20px;
	width: 100%;
	height: 25px;
	background: url('../images/wave.png') repeat-x;
}


.bg-Base_Pose {
    width: 238px; height: 337px;
    background: url('../images/toys-sprite.png') -1180px -10px;
}
.bg-Kamerton {
    width: 359px; height: 418px;
    background: url('../images/toys-sprite.png') -411px -10px;
}
.bg-Sever {
    width: 314px; height: 431px;
    background: url('../images/toys-sprite.png') -10px -463px;
}
.bg-SWIM {
    width: 292px; height: 416px;
    background: url('../images/toys-sprite.png') -344px -463px;
}
.bg-Tankist {
    width: 370px; height: 379px;
    background: url('../images/toys-sprite.png') -790px -10px;
}


.section__title {	
		 padding: 120px 0px;
		 @include tablet--big {
			padding: 70px 0px; 
		 }
}

.section__title img {	
	
	@include phone {
	  width: 350px;
	}
}

.subtitle img {	
	
	@include phone {
	  width: 350px;
	}
}

.toys__text {
	font-size: 30px;
font-weight: 400;
line-height: 36px;
font-family: "Bubbleboddy Neue Trial";
color: #fff;
text-transform: uppercase;
}
.toys__text p {
	text-transform: initial;
	margin:0px;
}
.toys__list {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	@include tablet {
		flex-wrap: wrap;
	}
}
.toys__item {
	width: 45%;
	@include tablet {
		width: 85%;
	}
}
.toys__img:nth-child(1) {
	background: url('../images/cloud-bg1.png') no-repeat;
	background-size: contain;
    background-position: 50%;	
}
.toys__img:nth-child(2) {
	background: url('../images/cloud-bg2.png') no-repeat;	
	background-size: contain;
    background-position: 50%;
}
.toys__img {
	height: 400px;	
	@include tablet--big {
		height: 300px;}	
	
}
.toys__img img{
	@include tablet--big {
	height: 280px;}	
	@include phone {
		height: 170px;
	}	
}
.subtitle {	
	padding: 100px 0px;
}
.subtitle--want {
	padding: 180px 0px 100px 0px;
}

@-moz-document url-prefix(){
	.subtitle--want {
		padding: 120px 0px 50px 0px;
	}	
}

.slider {
	display: flex;
	justify-content: center;	
	position: relative;	
	padding-bottom: 100px;
}

.img-wrap {
	display: flex;
	padding: 0 60px;
}

.img-wrap div {
	display: flex;
	flex-direction: column;	 
	background: url('../images/star-b.png') no-repeat;
	background-size: 180px;
	background-position: center 70px;
	padding: 0 2%;
}

.slider__img {
	margin: 0 auto;
	height: 350px;
}

.slick-prev {
	background: none;
	position: absolute;
	border:none;	
	z-index: 10;
    top: 160px;
	left: 10%;
	@media screen and (max-width: 1460px){
        left: 5%;
    }
	@include tablet--big {
		left:5px;
	}
}
.slick-next {
	background: none;
	position: absolute;
	border:none;	
	z-index: 10;
    top: 160px;
	right: 12%;
	@media screen and (max-width: 1460px){
        right: 6%;
    }
	@include tablet--big {
		right:5px;
	}
}
.slider__text {
	margin: 15px;
	@include font2;
	color:#fff;
	width: 250px;
}

.section__steps {
	background: url('../images/white-bg.png');
	background-size: cover;
	text-align: center;
	position: relative;
}
.steps__list {
	display: flex;
	justify-content: center;
	padding: 0 15px 40px 15px;
	@include tablet {
		flex-wrap: wrap;
	}
}
.step__item {
	width:30%;	
	text-align: left;
	display: flex;
	flex-direction: column;
	@include tablet--big {
		width:25%;
	}
	@include tablet {
		flex-wrap: wrap;
		width:80%;
		height: auto;
		padding:0 0 25px 0;			
	}	
}
 
.step__img { 
	background: url('../images/step-sprite.png') no-repeat top left;
	margin: 0 auto;
	position: relative;
}


.step3 { background-position: 0px 0px; width: 159px; height: 159px;  } 
.step2 { background-position: -169px 0px; width: 159px; height: 160px;  } 
.step1 { background-position: -338px 0px; width: 159px; height: 160px;  } 

.step__item:nth-child(2), .step__item:nth-child(3) {
	background: url(../images/gold-arr.png) no-repeat 0px 75px;
    padding-left: 150px;
	margin-left: 0; 
	@include tablet--big {
		padding-left: 100px;
	}	
	@include tablet {
		background: none;
		padding:0 0 25px 0;	
	}	
}
.step__text-title {
	@include font2;
	font-size: 30px;
	text-align: center;
}
.step__item-container {
	display: flex;
	flex-direction: column;
	height: 580px;
	@include tablet--big {
		height: 680px;
	}
	@include tablet {
		height: auto;		
	}		
}
.disc {
	list-style-type: disc;
	color: #fdb82f;
	padding-bottom: 10px;

}
.disc span {
	color:#000;
}
.terms {
	height: 60px;
}
.period {
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	margin: 2px 0;
}

.period span {
	display: inline-block;
	font-weight: normal;
	font-size: 16px;
	text-align: center;
}
.step__icon {
	background: url('../images/ic-sprite.png') no-repeat top left;
	margin: 15px auto;
}

.time-icon {
	width: 79px; height: 83px;
	background: url('../images/ic-sprite.png') -109px -10px;
	
}
.sale-icon {
	width: 79px; height: 83px;
    background: url('../images/ic-sprite.png') -10px -10px;
}
.sticker-icon {
	width: 79px; height: 83px;
    background: url('../images/ic-sprite.png') -208px -10px;
}

.bg-sticker {
    width: 79px; height: 83px;
    background: url('../images/ic-sprite.png') -208px -10px;
}

.a-wrapper {
	padding-bottom: 110px;
    padding-top: 20px;
}
.a-wrapper--bottom {
	margin-bottom: 150px;
	@include phone {
		margin-bottom: 0;
	}
}

.wave-w {
	position: absolute;
	top:-20px;
	width: 100%;
	height: 25px;
	background: url('../images/wave-w.png') repeat-x;
}
.wave-down {
	position: absolute;
	width: 100%;
	height: 26px;
	background: url('../images/wave-w.png') repeat-x;
	transform: rotate(180deg);
}
.section__prizes {
	background: url('../images/prize-bg.png');
	background-size: cover;
	text-align: center;
	background-position: -270px;
}
	
.prizes__list {
	display: flex;
	margin: 50px auto;
	justify-content: center;
	padding: 0;	
	margin: 0px auto 50px;
	@include tablet--big {
		margin: 50px 15px; 	
	}
	@include tablet {
		flex-wrap: wrap;
	}
}
.prizes__item {
	display: flex;
	flex-direction: column;
	width: 30%;
	padding: 0 45px;    
    align-items: center;
	justify-content: space-between;
	@include tablet--big {
		padding: 0 15px; 	
	}
	@include tablet {
		width: 80%;
		margin-bottom: 50px;
	}
}
	
.prizes__item .prizes__img img {
	display: block;
    border: 8px solid #f89d2d;
	border-radius: 30px;
	@include tablet--big {
		width: 280px;
	}
}
.prizes__img {
	position: relative;
}

.star {
	background: url('../images/places.png') no-repeat top left;
	position: absolute;
	left: 120px;
	top: 220px;
	@include tablet--big {
		left: 90px;
		top: 160px;
	}
}
.p1 {
	width: 124px; height: 124px;
    background: url('../images/places.png') -10px -10px;
}
.p2 {
	width: 128px; height: 128px;
    background: url('../images/places.png') -302px -10px;
}
.p3 {
	width: 128px; height: 128px;
    background: url('../images/places.png') -154px -10px;
}
.prizes__title {
	padding-top: 60px;
	@include font2;
	color: #094b7c;
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	text-align: left;
}
.prizes__slogan {
	color: #ffffff;
	@include font2;
	font-size: 20px;
	font-weight: 700;
	line-height: 27px;
	padding-top: 20px;
	padding-bottom: 30px;
	align-self: flex-start;
}
.prizes__text {
	color: #ffffff;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
}
.step__text2 {
	color:#fff;
	text-align: center;
	padding: 40px 0 0 0;
}

.section__join {
	background: url('../images/white-bg.png');
	background-size: cover;
	text-align: center;
	position: relative;
}
.counter {
    margin: 0 auto;
    background: url(../images/timer-bg.png) center no-repeat;
    background-size: contain; 
	height: 460px;	
    width: 100%;
	top: -230px;	
    position: absolute;
}

.counter p {
	@include font2;
	font-size: 38px;
font-weight: 700;
color: #fff;
padding-top: 60px;

@include phone {
	padding-top: 140px;
	font-size: 24px;
}

}

.timer__list {
	padding: 0;
	margin: 0 auto;
	width: auto;
	height: 200px;
	@include tablet {
		justify-content: center;
	}
	@include phone {
		height: 50px;
	}

}

.timer__list li {
	color: #ffce2d;
font-size: 25px;
font-weight: 300;
font-style: italic;
padding: 0 10px;
width: 160px;
display: inline-block;

@include phone {
	width: 50px;
	font-size: 16px;
}

}

.timer__list--num li {
	color: #0b5496;
  text-shadow: -0 -3px 0 #FFFFFF, 0 -3px 0   #FFFFFF,
 -0    3px 0   #FFFFFF,
 0    3px 0   #FFFFFF,
 -3px -0   0   #FFFFFF,
 3px -0   0   #FFFFFF,
 -3px  0   0   #FFFFFF,
 3px  0   0   #FFFFFF,
 -1px -3px 0   #FFFFFF,
 1px -3px 0   #FFFFFF,
 -1px  3px 0   #FFFFFF,
 1px  3px 0   #FFFFFF,
 -3px -1px 0   #FFFFFF,
 3px -1px 0   #FFFFFF,
 -3px  1px 0   #FFFFFF,
 3px  1px 0   #FFFFFF,
 -2px -3px 0   #FFFFFF,
 2px -3px 0   #FFFFFF,
 -2px  3px 0   #FFFFFF,
 2px  3px 0   #FFFFFF,
 -3px -2px 0   #FFFFFF,
 3px -2px 0   #FFFFFF,
 -3px  2px 0   #FFFFFF,
 3px  2px 0   #FFFFFF,
 -3px -3px 0   #FFFFFF,
 3px -3px 0   #FFFFFF,
 -3px  3px 0   #FFFFFF,
 3px  3px 0   #FFFFFF,
 -3px -3px 0   #FFFFFF,
 3px -3px 0   #FFFFFF,
 -3px  3px 0   #FFFFFF,
 3px  3px 0   #FFFFFF; 
 @include font2;
 font-size: 100px;
 //line-height: 100%;
 font-style: normal;
 height: 200px;
	 

 @include tablet--big {
	font-size: 60px;
 line-height: 60px; 
 }
 @include tablet {
	font-size: 25px;
 line-height: 25px; 
 }

 @include phone {
	height: 500px;
}
}

.timer__list--str {
	margin: -50px auto 0;
	@include phone {
		margin: 0;
	}
}
.per:after {
	content:':';
	color: #0b5496;
  text-shadow: -0 -3px 0 #FFFFFF, 0 -3px 0   #FFFFFF,
 -0    3px 0   #FFFFFF,
 0    3px 0   #FFFFFF,
 -3px -0   0   #FFFFFF,
 3px -0   0   #FFFFFF,
 -3px  0   0   #FFFFFF,
 3px  0   0   #FFFFFF,
 -1px -3px 0   #FFFFFF,
 1px -3px 0   #FFFFFF,
 -1px  3px 0   #FFFFFF,
 1px  3px 0   #FFFFFF,
 -3px -1px 0   #FFFFFF,
 3px -1px 0   #FFFFFF,
 -3px  1px 0   #FFFFFF,
 3px  1px 0   #FFFFFF,
 -2px -3px 0   #FFFFFF,
 2px -3px 0   #FFFFFF,
 -2px  3px 0   #FFFFFF,
 2px  3px 0   #FFFFFF,
 -3px -2px 0   #FFFFFF,
 3px -2px 0   #FFFFFF,
 -3px  2px 0   #FFFFFF,
 3px  2px 0   #FFFFFF,
 -3px -3px 0   #FFFFFF,
 3px -3px 0   #FFFFFF,
 -3px  3px 0   #FFFFFF,
 3px  3px 0   #FFFFFF,
 -3px -3px 0   #FFFFFF,
 3px -3px 0   #FFFFFF,
 -3px  3px 0   #FFFFFF,
 3px  3px 0   #FFFFFF; 
 @include font2;
 font-size: 100px; 
 font-style: normal;
 height: 200px;
 padding-left: 35px;
 @include tablet--big {
	font-size: 60px;
 line-height: 60px; 
 }
 @include tablet {
	font-size: 25px;
 line-height: 25px; 
 }
}


.btn {
	display: inline-block;
	background-color: #ed2224;
	border:4px solid #fff;
	border-radius:35px;	
	cursor: pointer;
	padding: 15px 40px; 
	line-height: 100%;
	box-shadow: 1px 2px 2px 0px #ccc; 		
}
.btn span {
	font-family: "Bubbleboddy Neue Trial";
	font-size: 22px;
	font-weight: 700;	
	color: #ffffff;
	}
.section__info {
	background: url('../images/last-sec-bg.png');
	background-size: cover;
	text-align: center;	
	position: relative;
}
.section__text {	
	text-align: left;
	color:#fff;
	padding: 280px 15px 15px 15px;
	font-size: 13px;
	@include phone {
		padding: 150px 15px 15px 15px;
	}
}
footer {
	display: flex;
	max-width: 1250px;
	margin:0 auto;
	padding: 30px 0;
	align-items: center;
	color:#fff;
	@include phone {
		flex-direction: column;
		justify-content: flex-start;
	}
	
}
.right-logo-block {
	margin-left: auto;
}


footer img {
	padding: 0 15px;
	
}
footer div {
	font-size: 13px;
	padding-right: 15px;
	@include phone {
		padding: 15px 10px 10px 0px;
	}
}
.right-logo-block img {
	padding: 0 15px;
}
#modal1 {
	display: none;
	width: 100%;
	max-width: 600px;
}

#modal2 {
	display: none;
	width: 100%;
	max-width: 600px;
}
#modal3 {
	display: none;
	width: 100%;
	max-width: 600px;
}
#modal1 > div {
	max-height: calc(100vh - 100px);
	overflow: auto;
}
#modal2 > div {
	max-height: calc(100vh - 100px);
	overflow: auto;
}
#modal3 > div {
	max-height: calc(100vh - 100px);
	overflow: auto;
}

#modal2 {
	border-radius:10px;
}

form {
	width: 540px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include phone {
		width: 320px;
	}
}

.form {
	width: 540px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@include phone {
		width: 320px;
	}
}

.modal__list {
	display: flex;
	flex-direction: column;
	padding: 0 0 0 70px;
	align-self:flex-start;
	@include phone {
		padding: 0;
	}	
}
.modal__title {
	@include font2;
	background: linear-gradient(0deg, #0B2349 15%, #0D61BC 66%, #8AA9D6);
	-webkit-background-clip: text;
	//-webkit-text-fill-color: transparent;
	color: #2a649b;
	display: table;
	font-size: 38px;
	font-weight: bold;
	margin: 0 auto;
	text-align: center;	
}
label {
	width: 120px;
	padding-right: 35px;
	@include phone {
		width: auto;
	padding: 0;
	}	
}
.input-wrapper label span {
	color: red;
}
.form__input {
	border-radius: 5px;
	border:1px solid grey;
}
.checkbox__label {
	width: 260px;
	padding: 15px 0 0 0;
}
.checkbox__text {
	font-size: 12px;
}
.input-wrapper {
	display: flex;
	height: 45px;
	align-items: center;
	@include phone {
		width: 290px;		
		display: flex;
		height: 70px;	
		flex-direction: column;
		justify-content: center;
	}	
}
.input {
	width: 300px;
}
.btn-form {
	display: flex;
	align-items: center;
	border: 4px solid #ffffff;
	justify-content: center;
	border-radius: 28px;
	height: 65px;	
	cursor: pointer;	
	padding: 0 15px;
	line-height: 65px;
	color:#fff;
	font-size: 18px;
	width: 417px;
	text-align: center;	
	background-color: #c2bebe;
	margin: 30px 0;
	@include phone {
		width: 280px;
	}	
}

.button-wraper {
	margin: 30px 0;
}
.button-wraper.button-wrapper--nonactive {
	display: none;
}
.button-wraper div {
	text-align: center;
	color: red;
	cursor: pointer;
	padding-top: 10px;
}
.btn-form--blue {
	background-color: #0880c2;
	background-image: linear-gradient(to top, #0c4688 0%, #0881c3 100%);
	border:4px solid #ebe8e8;
	box-shadow: 1px 2px 2px 0px #ccc;
	margin:0;	 
}
.btn-form--green {
	border: 1px solid #ebe8e8;
background-color: #056839;
box-shadow: 1px 2px 2px 0px #ccc;
margin:0;	  
}
.btn-form-send {
	box-shadow: 1px 2px 1px 1px #dddbdb;
}
.btn-form--blue img, .btn-form--green img {
	padding-right: 15px;
}

input[type="submit"] {
	@include font2;
	font-size: 22px;
	font-weight: bold;
	line-height: 100%;
}
input::placeholder {
	@include font1;	
	font-size: 16px;
}
input:focus:invalid {
	border: 4px solid red; }
  
  input:focus:valid,
  textarea:focus:valid {
	border: solid 2px #a7cdec;
 }

.fancybox-button svg{
	background: #bdbdbd;
    border-radius: 50%;
    padding: 2px;
}
.fancybox-navigation .fancybox-button--arrow_right, .fancybox-navigation .fancybox-button--arrow_left {
	display: none;
}
.fancybox-is-open .fancybox-bg {
	opacity:.7;
}
.id__block {
	font-size: 52px;
	padding: 30px 0;
	font-weight: bold;
}
.id__num {
	display: inline;
	font-weight: bold;
}
.form__pic {
	height: 280px;
}

